function runMap(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runMap(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('Map', {
        /**
         * Modeled data for this controller
         * @var {Object} model
         */
        model: {
            geoFetchCount: 'int',
            markers: 'array'
        },

        /** map */
        map: null,

        /** {Array} locations */
        locations: [],

        /** {Boolean} hasTimedOut */
        hasTimedOut: false,

        /**
         * Runs on controller initialize
         */
        init: function() {
            // Save reference to the controller
            var self = this;

            if (! self.hasTimedOut) {
                self.hasTimedOut = true;
                setTimeout(function() {
                    self.init();
                }, 1000);
                return;
            }

            // Set up the map
            self.map = new window.google.maps.Map(this.el, {
                center: new window.google.maps.LatLng(0, 0),
                scrollwheel: false,
                zoom: 1
            });

            // Set initial model value
            self.model.set('geoFetchCount', 0);

            // Set the locations
            self.locations = self.$el.data('locations');

            // Populate geo data
            self.populateGeoData();

            // Watch for change to geoDone
            self.model.onChange('geoFetchCount', function(val) {
                // If we've geocoded all of them, set up markers
                if (val >= self.locations.length) {
                    self.setMarkers();
                }
            });
        },

        /**
         * Iterates through locations and sends to get geo data
         */
        populateGeoData: function() {
            // Save reference to the controller
            var self = this;

            // Save reference to locations
            var locs = self.locations;

            // Iterate through locations
            for (var i = 0; i < locs.length; i++) {
                // Set geo data
                self.setGeoData(locs[i].address, i);
            }
        },

        /**
         * Sets the geo data to the location array at specified index and
         * updates the geoDone model property to trigger watchers
         * @param {String} address The address to geo code
         * @param {Number} i the location index to set
         */
        setGeoData: function(address, i) {
            // Save reference to the controller
            var self = this;

            // Run ajax call to geocode api
            $.ajax({
                url: '/ajax/google-maps-geocode-api',
                data: {
                    endPoint: '/maps/api/geocode/json',
                    address: address,
                    sensor: 'false'
                },
                success: function(obj) {
                    var parser = document.createElement('a');
                    parser.href = obj.scriptUrl;

                    $.ajax({
                        url: parser.pathname,
                        dataType: 'json',
                        success: function(json) {
                            // Add the geocoding data to the location array
                            self.locations[i].geocoding = json;

                            // Update the fetch count
                            self.model.set(
                                'geoFetchCount',
                                self.model.get('geoFetchCount') + 1
                            );
                        }
                    });
                }
            });
        },

        /**
         * Set markers
         */
        setMarkers: function() {
            // Save reference to the controller
            var self = this;

            // Set variable for locations
            var locs = self.locations;

            // Create bounds
            var bounds = new window.google.maps.LatLngBounds();

            // Make sure map has been set
            if (! self.map) {
                setTimeout(function() {
                    self.setMarkers();
                }, 10);
                return;
            }

            // Iterate through locations
            for (var i = 0; i < locs.length; i++) {
                // Create new scope
                (function(loc) {
                    // Get geometry location
                    var l = loc.geocoding.results[0].geometry.location;

                    // Get maps LatLng
                    var latLng = new window.google.maps.LatLng(
                        l.lat,
                        l.lng
                    );

                    // Set infoWindow variable
                    var infoWindow;

                    // Set marker variable
                    var marker;

                    // Set pin
                    var pin = new window.google.maps.MarkerImage(
                        '/assets/img/map-pin.png',
                        null,
                        null,
                        null,
                        new window.google.maps.Size(30, 41)
                    );

                    // Set the content string
                    var contentString = '<p><strong>' + loc.title + '</strong>';
                    contentString += '<br>' + loc.address;

                    if (loc.phone) {
                        contentString += '<br><a href="tel:' + loc.phone + '">';
                        contentString += loc.phone + '</a>';
                    }

                    contentString += '<br><br><a target="_blank" href="';
                    contentString += 'https://www.google.com/maps/dir//';
                    contentString += loc.address;
                    contentString += '">Get Directions &raquo;</a>';
                    contentString += '</p>';

                    // Create an info window
                    infoWindow = new window.google.maps.InfoWindow({
                        content: contentString
                    });

                    // Create the marker
                    marker = new window.google.maps.Marker({
                        position: latLng,
                        icon: pin,
                        map: self.map,
                        title: loc.title
                    });

                    // Add info window click listener
                    marker.addListener('click', function() {
                        infoWindow.open(self.map, marker);
                    });

                    // Extend bounds
                    bounds.extend(l);
                })(locs[i], i);
            }

            // Fit map to markers
            self.map.fitBounds(bounds);

            // Make sure zoom is not greater than 16
            if (self.map.zoom > 16) {
                self.map.setZoom(16);
            }

            // Wait a minute and check again in case the first check failed
            setTimeout(function() {
                if (self.map.zoom > 16) {
                    self.map.setZoom(16);
                }
            }, 800);
        }
    });
}

runMap(window.FAB, window);
